<template>
  <div class="benefit-withdraw">
    <nabbar bg="transparent" fixed>
      <template #right>
        <span class="link" @click="$router.push('withdraw-log')">提现记录</span>
      </template>
    </nabbar>
    <div class="dashboard">
      <div class="dashboard-item">
        <div class="label">可提现（元）</div>
        <div class="money">{{ userInfo.can_withdraw }}</div>
      </div>
      <div class="withdraw-form">
        <input
          v-model="withdrawForm.name"
          class="text-input"
          placeholder="请输入姓名"
        />

        <input
          v-model="withdrawForm.bank"
          class="text-input"
          placeholder="请输入所属银行"
        />
        <input
          v-model="withdrawForm.bankopen"
          class="text-input"
          placeholder="请输入开户行"
        />
        <input
          v-model="withdrawForm.user_name"
          class="text-input"
          placeholder="请输入账号"
        />
        <input
          v-model="withdrawForm.mobile"
          class="text-input"
          placeholder="请输入手机号"
        />
        <input
          v-model="withdrawForm.money_withdraw"
          class="text-input"
          placeholder="请输入提现金额"
        />
      </div>
      <div class="submit-btn" @click="handleWithdraw">
        申请提现
      </div>
    </div>
    <tips ref="tips">
      <img class="icon" src="@/assets/success.png" alt="" />
      <div class="tips-title">你的提现申请已提交，请等待后台打款</div>
      <button class="confirm-next-btn" @click="$refs.tips.hide()">确定</button>
    </tips>
    <van-action-sheet
      v-model="showBankSelect"
      :actions="bankOptions"
      @select="handleSelectBank"
    />
    <van-popup v-model="showBankInput">
      <div class="bank-input">
        <div>请输入银行</div>
        <input v-model="withdrawForm.bank" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Nabbar from "@/components/h5/Navbar.vue";
import Tips from "@/components/h5/Tips";
import { ActionSheet, Popup } from "vant";

import apis from "@/apis/index";

const initForm = () => ({
  bank: "",
  bankopen: "",
  name: "",
  user_name: "",
  mobile: "",
  money_withdraw: ""
});

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "BenefitWithdraw",
  components: {
    Nabbar,
    Tips,
    "van-action-sheet": ActionSheet,
    "van-popup": Popup
  },
  data() {
    return {
      withdrawForm: initForm(),
      showBankSelect: false,
      showBankInput: false,
      bankOptions: [
        {
          name: "中国银行",
          value: "中国银行"
        },
        {
          name: "中国农业银行",
          value: "中国农业银行"
        },
        {
          name: "中国工商银行",
          value: "中国工商银行"
        },
        {
          name: "中国建设银行",
          value: "中国建设银行"
        },
        {
          name: "交通银行",
          value: "交通银行"
        },
        {
          name: "支付宝",
          value: "支付宝"
        },
        {
          name: "其他",
          value: "其他"
        }
      ]
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  activated() {
    this.withdrawForm = initForm();
  },
  methods: {
    async handleWithdraw() {
      await apis.userWithdraw(this.withdrawForm);
      this.$refs.tips.show();
    },
    handleSelectBank(item) {
      this.showBankSelect = false;
      if (item.value === "其他") {
        this.showBankInput = true;
        return;
      }
      this.withdrawForm.bank = item.value;
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss">
.withdraw-form-item {
  margin-bottom: 30px;

  &.no-after {
    .el-form-item__label {
      &::after {
        // display: none;
        opacity: 0;
      }
    }
  }

  .el-form-item__label {
    text-align: left;
    font-size: 12px;
    color: #333;

    &::after {
      content: "：";
    }
  }
}
</style>

<style lang="scss" scoped>
.benefit-withdraw {
  // margin: 20px;
  box-sizing: border-box;

  .link {
    color: #148bfa;
  }

  .dashboard {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 auto;
    margin-top: 40px;
    padding: 60px 0;
    box-sizing: border-box;
    // height: 580px;
    width: 1045px;
    background-color: #fff;
    border-radius: 10px;

    .dashboard-item {
      // width: 330px;
      // height: 120px;
      flex: none;
      width: 965px;
      padding-bottom: 70px;
      margin-bottom: 40px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: #333;
      position: relative;
      border-bottom: 1px solid #f0f0f0;

      .money {
        font-size: 80px;
        font-weight: bold;
        margin-top: 38px;
      }

      .label {
        font-size: 44px;
      }
    }

    .withdraw-form {
      display: flex;
      flex-flow: column nowrap;
      width: 965px;
    }

    .text-input {
      height: 100px;
      font-size: 40px;
      line-height: 100px;
      color: #777;
      border: none;
      border-bottom: 1px solid #f0f0f0;
    }

    .submit-btn {
      margin-top: 60px;
      width: 965px;
      height: 100px;
      background: #CA0A15;
      border-radius: 10px;
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.icon {
  height: 200px;
  width: 200px;
  margin-top: 90px;
}
.tips-title {
  margin-top: 69px;
  font-size: 56px;
  color: #ff5500;
}

.tips-desc {
  margin-top: 39px;
  font-size: 40px;
  color: #666666;

  .remain {
    color: #ff5500;
  }
}

.confirm-next-btn {
  margin-top: 80px;
  height: 120px;
  width: 965px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #CA0A15;
  color: #ffffff;
  font-size: 56px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}
</style>
